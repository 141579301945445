export var replaceUrl = function (url, inflowSource) {
    if (url == null) {
        return url;
    }
    url = url.replace('{_src}', encodeURIComponent(inflowSource));
    try {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('_src');
        for (var _i = 0, _a = Array.from(searchParams.keys()); _i < _a.length; _i++) {
            var key = _a[_i];
            var value = searchParams.get(key);
            url = url.replace("{".concat(key, "}"), encodeURIComponent(value || ''));
        }
        return url;
    }
    catch (e) {
        console.error(e);
        return url;
    }
};
