var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { API_END_POINT } from './api';
export default function useSite(siteId, domain, path, siteTemplateKey, recordKey) {
    var _this = this;
    var _a = useState(), setting = _a[0], setSetting = _a[1];
    var _b = useState(), locationJson = _b[0], setLocationJson = _b[1];
    var _c = useState(), shopReviews = _c[0], setShopReviews = _c[1];
    var _d = useState({}), icons = _d[0], setIcons = _d[1];
    var _e = useState(), seoSetting = _e[0], setSeoSetting = _e[1];
    var _f = useState(true), loading = _f[0], setLoading = _f[1];
    var _g = useState(false), authorized = _g[0], setAuthorized = _g[1];
    var _h = useState(), errorTitle = _h[0], setErrorTitle = _h[1];
    var _j = useState(), errorMessage = _j[0], setErrorMessage = _j[1];
    // サイトテンプレートIDが指定されている場合はサムネイル画像キャプチャ用に
    // サイトテンプレートの情報で画面を表示します。
    // 通常は呼び出されない内部的な表示です。
    var url = siteTemplateKey
        ? "".concat(API_END_POINT, "/public/site-templates/").concat(siteTemplateKey)
        : "".concat(API_END_POINT, "/public/sites/").concat(siteId);
    var handleResponse = useCallback(function (response) {
        setSetting(response.data.setting);
        setLocationJson(response.data.locationJson);
        setShopReviews(response.data.shopReviews);
        setIcons(response.data.icons);
        setSeoSetting(response.data.seoSetting);
        setAuthorized(true);
        setErrorTitle(undefined);
        setErrorMessage(undefined);
        // レコードキーが指定されていない場合かつランダム適用されたレコードキーがある場合は
        // URLにレコードキーを追加して書き換える(画面遷移はしない)
        if (recordKey == null && response.data.appliedRecordKey != null) {
            var url_1 = new URL(window.location.href);
            url_1.searchParams.set('recordKey', response.data.appliedRecordKey);
            window.history.replaceState({}, '', url_1.toString());
        }
    }, []);
    var handleError = useCallback(function (error) {
        var _a, _b, _c, _d;
        if (error.response && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            setAuthorized(false);
        }
        else if (error.response && ((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 404) {
            setErrorTitle('404 Not Found');
            setErrorMessage('サイトが非公開、もしくは存在しないため読み込めませんでした。');
        }
        else if (error.response && ((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 500) {
            setErrorTitle('500 Internal Server Error');
            setErrorMessage('サーバー内部でエラーが発生しています');
        }
        else if (error.response && ((_d = error.response) === null || _d === void 0 ? void 0 : _d.status) === 502) {
            setErrorTitle('502 Bad Gateway');
            setErrorMessage('サーバー間の通信エラーが生じています');
        }
        else {
            setErrorTitle('OTHER ERROR');
            setErrorMessage('通常とは異なるエラーが生じています');
        }
    }, []);
    var fetchSite = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    if (!siteId && !isCustomDomain(domain)) {
                        setLoading(false);
                        setErrorTitle('404 Not Found');
                        setErrorMessage('サイトが非公開、もしくは存在しないため読み込めませんでした。');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axios.get(url, {
                            params: { domain: domain, path: path, recordKey: recordKey },
                        })];
                case 2:
                    response = _a.sent();
                    handleResponse(response);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    handleError(error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var fetchProtectedSite = function (password) { return __awaiter(_this, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    if (!siteId && !isCustomDomain(domain)) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axios.post(url, {
                            domain: domain,
                            path: path,
                            recordKey: recordKey,
                            password: password,
                        })];
                case 2:
                    response = _a.sent();
                    handleResponse(response);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    handleError(error_2);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        void fetchSite();
    }, [siteId, siteTemplateKey, recordKey]);
    return {
        setting: setting,
        locationJson: locationJson,
        shopReviews: shopReviews,
        icons: icons,
        seoSetting: seoSetting,
        isLoadingSite: loading,
        authorized: authorized,
        fetchProtectedSite: fetchProtectedSite,
        errorTitle: errorTitle,
        errorMessage: errorMessage,
    };
}
var isCustomDomain = function (domain) {
    return !['site.locaop.jp', 'localhost'].includes(domain);
};
